import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InternalLink from "../components/internal_link"
import ExternalLinkNewTab from "../components/external_link_new_tab"


const KontaktPage = () => (
  <Layout>
    <SEO title="Kontakt" />
      <h1>KONTAKT</h1>
      <div>Ich schreibe Witze, Drehbücher und, wenn alles glatt läuft, meinen eigenen Namen auf Rechnungen. <br></br><br></br>
      Vertreten werde ich durch die Agentur Page Magnet: <ExternalLinkNewTab destination="http://www.pagemagnet.de/portfolio/jan-krebs/" label="LINK"/><br/><br/>
      Ich bin immer auf der Suche nach neuen Projekten.
      <p><a style={{color: "blue"}}href="mailto:hallo%40jankrebs.com">Hier kann man mich erreichen.</a></p>
      </div>
    <InternalLink destination="/" label="ZURÜCK"/>
  </Layout>
)

export default KontaktPage