import React from "react"

const ExternalLinkNewTab = ({ destination, label }) => (
    <a
        style={{
            color: "blue"
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={destination}
    >
        LINK
    </a>
);

export default ExternalLinkNewTab